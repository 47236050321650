import React, { useState } from 'react';

import { MenuItem, CardText, DropDownMenu, FlatButton } from 'material-ui';

import { useTranslation } from 'react-i18next';
import { Route } from '../betterTypes';

interface ExportRouteProps {
  selectedRoute: number;
  storedRoutes: Route[];
  handleExportRoute: (routeName: string, format: number, selectedRoute: number) => void;
}

const exportOptions = [
  {
    id: 'gpx',
    name: 'GPX',
  },
  {
    id: 'kml',
    name: 'KML',
  },
  {
    id: 'csv',
    name: 'CSV',
  },
  {
    id: 'mapsource',
    name: 'Garmin Mapsource',
  },
  {
    id: 'lowranceusr',
    name: 'Lowrance USR',
  },
];

const styles = {
  button: {
    width: '90%',
    margin: '5%',
  },
};

function ExportRoute({ selectedRoute, storedRoutes, handleExportRoute }: ExportRouteProps) {
  const { t } = useTranslation();
  const [format, setFormat] = useState(-1);

  return (
    <>
      <CardText>{t('routes.export')}</CardText>
      <DropDownMenu
        disabled={selectedRoute === -1}
        maxHeight={300}
        value={format}
        onChange={(_event, _index, value) => setFormat(value)}
        style={{ width: '100%' }}
      >
        {[{ id: '-1', name: t('routes.export-options') }].concat(exportOptions).map((exportOption) => (
          <MenuItem value={exportOption.id} key={exportOption.id} primaryText={exportOption.name} />
        ))}
      </DropDownMenu>
      <FlatButton
        label={t('routes.export-route')}
        style={styles.button}
        disabled={format === -1}
        onMouseUp={() => {
          const findRoute = storedRoutes.find((o) => o.id === selectedRoute);
          if (findRoute) {
            handleExportRoute(findRoute.name, format, selectedRoute);
          }
        }}
      />
    </>
  );
}

export default ExportRoute;
